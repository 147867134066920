import $ from "jquery";
window.$ = window.jQuery = $;

import '../../node_modules/components-jqueryui/jquery-ui.js';
import Sortable from 'sortablejs';


(function ($) {
    "use strict"; // Start of use strict

    jQuery.noConflict();

    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        }
    });

    //filter
    $(".filter").on('keyup', function () {
        var value = $(this).val().toLowerCase();
        var what = $(this).data('filterid');

        $(".table tr" + what).filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });

    //date-calendar
    if($(".datepicker").length) {
        $(".datepicker").datepicker({
            showButtonPanel: true,
            dateFormat: "yy-mm-dd"
        });
    }

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function () {
        if ($(window).width() < 768) {
            $('.sidebar .collapse').collapse('hide');
        }
    });

    if($('#tableitems').length > 0) {
        Sortable.create(tableitems, {
            group: 'tableitems',
            handle: '.handle',
            animation: 100,
            dataIdAttr: 'data-id',

            store: {
                set: function (sortable) {
                    var order = sortable.toArray();

                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    jQuery.ajax({
                        url: location.href + '/sort',
                        method: 'POST',
                        data: {
                            order: order,
                        },
                        success: function(result){
                            $(".alert").replaceWith(result);

                            setTimeout(function(){
                                $(".alert").fadeOut();
                            }, 3000);
                        }
                    });
                },

            },
        });
    }


    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
            var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
        }
    });

    // Scroll to top button appear
    $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top)
        }, 1000);
        e.preventDefault();
    });

    $(".delete").click(function() {
        var id = $(this).data('id');

        $("form#delete").attr('action', window.location.href + '/' + id);
    })

    // All checkbox when assigning user to group(s)
    $('#group-0').attr('name', 'all-groups')
        .siblings('.form-check-label')
        .replaceWith('<label for="group-0" class="font-weight-bold">Select All Groups</label>');

    $('#is_member-0').attr('name', 'all-members')
        .siblings('.form-check-label')
        .replaceWith('<label for="is_member-0" class="font-weight-bold">Select All Members</label>');

    $('input[name="all-groups"], input[name="all-members"]').on('change', function(e) {
        var checkboxes = $(this).closest('.form-group').find(':checkbox');
        checkboxes.prop('checked', $(this).is(':checked'));
    })

    $("#add-field").click(function(e) {
        e.preventDefault();

        $("#email_address").clone(false).val('').appendTo($(this).parents('.form-group'));
    })

    $("button.guest.add").on('click', function(e) {
        let table_rows = $("table.guests tr");
        let last_row = $("table.guests").find("tr:last");

        if(table_rows.length > 1) {
            let clone = last_row.clone(true, true).insertAfter(last_row.last());

            clone.find('input').val('').end();
        }
        else {
            last_row.after('<tr><td><input type="text" value="" name="first_name[]" class="form-control " id="name" placeholder="First name" maxlength="255" data-pattern-error="Only letters, spaces are allowed"></td><td><input type="text" value="" name="last_name[]" class="form-control " id="last_name" placeholder="Last name" maxlength="255" data-pattern-error="Only letters, spaces are allowed"></td><td><input type="text" value="" name="company[]" class="form-control " id="company" placeholder="Company name" maxlength="255" data-pattern-error="Only letters, spaces are allowed"></td><td><button class="delete btn">Delete</button></td></tr>')
        }
    })

    $("table.guests").on('click', 'button.delete', {}, function(e) {
        $(this).closest('tr').remove();
    })

    $("body").on('click', '#archived-groups-button', {}, function(e) {
        $("#archived-groups").toggle(0, function() {
            if($('#archived-groups-button').text() == 'Show') {
                $('#archived-groups-button').text('Hide');
            } else if($('#archived-groups-button').text() == 'Hide') {
                $('#archived-groups-button').text('Show');
            }
        });
    });

    $(document).on('click', '.calendar-shortcut', {}, function(e) {
        let $anchor = $(".calendar-of-events");

        $('html, body').animate({
            scrollTop: ($anchor.offset().top)
        }, 500);
        e.preventDefault();
    });



    $(".card-body").on('change', 'select#folder_type', {}, function() {
        let value = $( "select#folder_type option:selected" ).val();
        if(value == 1) {
            $(".confidential-folder").hide()
            $(".taskforce-archived").show();
        }
        else {
            $(".confidential-folder").show();
            $(".taskforce-archived").hide();
        }
    });

    $('input[type=radio][name=disclaimer]').change(function() {
        if (this.value == 1) {
            $('.disclaimer-text').show()
        }
        else {
            $('.disclaimer-text').hide();
        }
    });




    $(document).ready(() => {

        if($('input[type=radio][name=disclaimer]:checked').val() == 1) {
            $('.disclaimer-text').show();
        }
        else {
            $('.disclaimer-text').hide();
        }

        if ($("#calendarEvent").length > 0) {

            let calendarEl = document.getElementById('calendar');

            let calendar = new FullCalendar.Calendar(calendarEl, {
                plugins: ['dayGrid', 'bootstrap'],
                themeSystem: 'bootstrap',
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                },
                header: {
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                events: '/event/list',
                eventRender: function (event) {
                    renderEvent(event);
                }
            });

            function renderEvent(event) {
                let el = $(event.el);
                let calendarModal = $('#calendarEvent');
                let dateValue = event.event.start;

                let shortMonth = dateValue.toLocaleString('default', {month: 'short'});
                let shortDay = dateValue.toLocaleString('default', {day: '2-digit'});

                let formatDate = function (date) {
                    return calendar.formatDate(date, {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                        weekday: 'long',
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZone: 'GMT'
                    })
                }

                el.click(function (fn) {
                    let map = new GMaps({
                        el: '#google-map-event',
                        lat: event.event.extendedProps.venue_lat,
                        lng: event.event.extendedProps.venue_lng,
                        zoom: $('#google-map-event').data('zoom'),
                        zoomControl: true,
                        scaleControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                    });

                    map.addMarker({
                        lat: event.event.extendedProps.venue_lat,
                        lng: event.event.extendedProps.venue_lng,
                    });

                    calendarModal.find('#calendarTitle').replaceWith('<div id="calendarTitle"><h3>' + event.event.title + '</h3></div>');
                    calendarModal.find('#calendarDescription').replaceWith('<div id="calendarDescription" class="mb-4"><h5>' + event.event.extendedProps.description + '</h5></div>');
                    calendarModal.find('#calendarVenue').replaceWith('<div id="calendarVenue" class="mb-1"><p><i class="fas fa-map-marker-alt"></i> ' + event.event.extendedProps.venue + '</p></div>');
                    calendarModal.find('#calendarVenueAddress').replaceWith('<div id="calendarVenueAddress" class="mb-2"><p>' + event.event.extendedProps.venue_address + '</p></div>');
                    calendarModal.find('#calendarStart').replaceWith('<div id="calendarStart" class="mb-2"><i class="far fa-calendar-alt"></i> <p class="font-weight-bold"><p>' + formatDate(event.event.start) + '</p></div>');
                    calendarModal.find('#calendarEnd').replaceWith('<div id="calendarEnd" class="mb-2"><p class="font-weight-bold"><p>' + formatDate(event.event.end) + '</p></div>');
                    calendarModal.find('.calendarIconDate .month').replaceWith('<div class="month"><p>' + shortMonth + '</p></div>');
                    calendarModal.find('.calendarIconDate .day').replaceWith('<div class="day"><p>' + shortDay + '</p></div>');
                    calendarModal.find('.download-ics').attr('href', 'event/' + event.event.id + '/download');

                    window.$('#calendarEvent').modal();
                });
            }

            calendar.render();
        }
    })
})($); // End of use strict
